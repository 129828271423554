import { createContext, ReactNode, useContext } from 'react'

import { Funnel } from '@helmur/common/dist/types'

import useAccountByUsername from '../common/hooks/useAccountByUsername'
import { Account, Role, Status, User } from '../common/models'
import { useAuth } from './auth-provider'

export interface AccountContextProps extends Account {
  currentUser: User
  users: User[]
  funnels: Funnel[]
}

type AccountContextType = {
  account: AccountContextProps
}

const AccountContext = createContext<AccountContextType>({
  account: {
    id: '',
    name: '',
    currentUser: {
      id: '',
      accountId: '',
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      confirmed: false,
      role: Role.owner,
      status: Status.inactive,
      confirmTokens: [],
    },
    users: [],
    funnels: [],
  },
})

export const useAccountContext = () => {
  const context = useContext(AccountContext)
  if (!context) throw new Error('Toast context must be use inside ToastProvider')
  return context
}

const AccountProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth()
  const { record } = useAccountByUsername(user?.sub)

  return <AccountContext.Provider value={{ account: record }}>{children}</AccountContext.Provider>
}

export default AccountProvider
